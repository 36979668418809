// src/components/SessionTimeoutHandler.tsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SessionTimeoutHandler: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false); // Estado para controlar el popup
  const navigate = useNavigate();

  // Función para verificar si el token ha expirado
  const isTokenExpired = (): boolean => {
    const expirationTime = localStorage.getItem("accessTokenExpiration");
    if (!expirationTime) return true; // Si no hay tiempo de expiración, considera que el token ha expirado
    return Date.now() > parseInt(expirationTime, 10); // Verifica si el tiempo actual es mayor al tiempo de expiración
  };

  // Configurar un interceptor de Axios para manejar la expiración del token solo en rutas que contengan "api/admin"
  useEffect(() => {
    const interceptor = axios.interceptors.request.use(
      async (config) => {
        // Filtrar solo las solicitudes que van a "api/admin"
        if (config.url && config.url.includes("api/admin")) {
          if (isTokenExpired()) {
            // Si el token ha expirado, muestra el popup
            setShowPopup(true);
            return Promise.reject(new Error("Token expired"));
          }

          // Si el token es válido, agrega el encabezado de autorización
          const token = localStorage.getItem("accessToken");
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Limpiar el interceptor cuando el componente se desmonte
    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, []);

  // Función para manejar el cierre del popup
  const handleClosePopup = () => {
    // Limpiar la sesión y redirigir al usuario al inicio de sesión
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessTokenExpiration");
    setShowPopup(false); // Ocultar el popup
    navigate("/login"); // Redirigir al inicio de sesión
  };

  return (
    <>
      {showPopup && (
        <div className="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-lg text-center">
            <h2 className="text-xl font-bold mb-4">Sesión Expirada</h2>
            <p className="mb-4">
              Tu sesión ha expirado. Por favor, inicia sesión de nuevo.
            </p>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={handleClosePopup}
            >
              Aceptar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SessionTimeoutHandler;
