import React, { useEffect, useState, useRef, useCallback } from "react";
import { centroid } from "@turf/turf";
import { useMap } from "src/context/MapContext";
import { GeoJSONData } from "src/types/geojson";
import axios from "axios";
import { useGeographicSelection } from "src/context/GeographicSelectionContext";
import { createLayerStyle } from "src/views/InteractiveMap/mapStyleBuilder";
import GridComponent from "src/components/GridComponent";
import MapFilterSelect from "./MapFilterSelect";
import CountUp from "react-countup";
import Loader from "src/components/Loader";
import { AvailableFilter } from "src/types/graph";
import { buildFiltersBody, updateFilters } from "src/utils/filterUtils";
import { Feature, FeatureCollection, Geometry, Point } from "geojson";
import AttractionsManager from "../InteractiveMap/AttractionsManager";
import { Popup } from "react-map-gl";
import { useWelcomeGuide } from "src/hooks/useWelcomeGuide";
import HelpWidget from "src/components/HelpWidget";
import LayerActionMenu from "./LayerActionMenu";

import { FaPlaneArrival, FaTree } from "react-icons/fa"; // Avión para Destinos turísticos y Árbol para TPT
import { MdNaturePeople } from "react-icons/md"; // Naturaleza con personas para ZOIT
import { PiMountainsFill } from "react-icons/pi";

export enum LayerOption {
  ATTRACTIVES = "/api/geojson/attractions",
  DESTINATIONS = "/api/geojson/destinations",
  TPT = "/api/geojson/tpt",
  ZOIT = "/api/geojson/zoit",
}

// status: "Prórroga"
export const LAYER_LABELS: Record<LayerOption, string> = {
  [LayerOption.ATTRACTIVES]: "Atractivos turísticos",
  [LayerOption.DESTINATIONS]: "Destinos turísticos",
  [LayerOption.TPT]: "Territorios con Potencial Turístico (TPT)",
  [LayerOption.ZOIT]: "Zonas de Interés Turístico (ZOIT)",
};

export const LAYER_ICONS: Record<LayerOption, React.ElementType> = {
  [LayerOption.ATTRACTIVES]: MdNaturePeople, // Montaña para atractivos turísticos
  [LayerOption.DESTINATIONS]: FaPlaneArrival, // Avión para destinos turísticos
  [LayerOption.TPT]: FaTree, // Árbol para territorios con potencial turístico
  [LayerOption.ZOIT]: PiMountainsFill, // Naturaleza con personas para Zonas de Interés Turístico
};

export const LAYER_SHORT_LABELS: Record<LayerOption, string> = {
  [LayerOption.ATTRACTIVES]: "Atractivos",
  [LayerOption.DESTINATIONS]: "Destinos",
  [LayerOption.TPT]: "TPT",
  [LayerOption.ZOIT]: "ZOIT",
};

export interface AttractionProperties {
  [name: string]: any;
}

interface AttractionData {
  availableFilters: AvailableFilter[];
  geojson: FeatureCollection<Point, AttractionProperties>;
}

const TouristView: React.FC = () => {
  const { mapRef } = useMap();
  const [selectedLayer, setSelectedLayer] = useState<LayerOption>(
    LayerOption.ATTRACTIVES
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [mapData, setMapData] = useState<GeoJSONData | null>(null);
  const [popupInfo, setPopupInfo] = useState<AttractionProperties | null>(null);

  const [attractionData, setAttractionData] = useState<FeatureCollection<
    Point,
    AttractionProperties
  > | null>(null);
  const [filters, setFilters] = useState<AvailableFilter[] | null>(null);
  const lastFilters = useRef<AvailableFilter[] | null>(null);

  const { selectedUnits, setSelectedUnits } = useGeographicSelection();

  // Navegación
  // (Foto) Puedes seleccionar una región y navegar por los atractivos.

  // Uso de filtros
  // (Foto) Puedes utilizar los filtros ubicados en la parte superior derecha del sitio y elegir entre Regiones, Comunas y Categorías.

  // Volver al inicio
  // (Foto) Para volver a la vista inicial, debes presionar el botón Turismo, ubicado en la parte inferior izquierda de la pantalla.
  useWelcomeGuide(
    [
      {
        title: "Bienvenido a la vista turística",
        subtitle:
          "Al entrar al modo Turista, podrás navegar por Chile y entre sus regiones descubriendo atractivos turísticos.",
        image: "/assets/welcome-guide/tourist/tourist-1-desktop.webp",
        mobileImage: "/assets/welcome-guide/tourist/tourist-1-mobile.webp",
      },
      {
        title: "Navegación",
        subtitle: "Puedes seleccionar una región y navegar por los atractivos.",
        image: "/assets/welcome-guide/tourist/tourist-2-desktop.webp",
        mobileImage: "/assets/welcome-guide/tourist/tourist-2-mobile.webp",
      },
      {
        title: "Uso de filtros",
        subtitle:
          "Puedes utilizar los filtros ubicados en la parte superior derecha del sitio y elegir entre Regiones, Comunas y Categorías.",
        image: "/assets/welcome-guide/tourist/tourist-3-desktop.webp",
        mobileImage: "/assets/welcome-guide/tourist/tourist-3-mobile.webp",
      },
      {
        title: "Volver al inicio",
        subtitle:
          "Para volver a la vista inicial, debes presionar el botón Turismo, ubicado en la parte inferior izquierda de la pantalla.",
        image: "/assets/welcome-guide/tourist/tourist-4-desktop.webp",
        mobileImage: "/assets/welcome-guide/tourist/tourist-4-mobile.webp",
      },
    ],
    TouristView.name
  );

  const handleLayerChange = async (
    key: string,
    selectedOption: string | number | Array<string | number> | null
  ) => {
    setPopupInfo(null);
    const selected = selectedOption as LayerOption;
    setSelectedLayer(selected);

    const map = mapRef.current?.getMap();
    if (!map) return;

    const enableRegionInteraction = selected === LayerOption.ATTRACTIVES;
    // setRegionInteractionEnabled(enableRegionInteraction);

    if (!enableRegionInteraction) {
      // setSelectedUnits([]);

      setIsLoading(true);
      try {
        const response = await axios.get(selected);
        const data: GeoJSONData = response.data;
        if (data?.features) {
          data.features = data.features.map((feature, index) => ({
            ...feature,
            id: index.toString(),
          }));
        }
        setIsLoading(false);
        setMapData(data);
      } catch (error) {
        console.error(`Error fetching ${selected}:`, error);
      }
    } else {
      setMapData(null);
    }
  };

  useEffect(() => {
    const map = mapRef.current?.getMap();
    if (!map || selectedLayer === LayerOption.ATTRACTIVES) return;

    // const fillLayerId = `${selectedLayer}-fill`;
    // const borderLayerId = `${selectedLayer}-border`;
    const fillLayerId = "draws-fill";
    const borderLayerId = "draws-border";
    // console.log("Creating layers");
    // console.log("fillLayerId", fillLayerId);
    // console.log("borderLayerId", borderLayerId);

    let hoveredRegionId: string | null = null;

    const onMouseMove = (e: any) => {
      if (hoveredRegionId !== null) {
        map.setFeatureState(
          { source: selectedLayer, id: hoveredRegionId },
          { hover: false }
        );
      }
      const featureId = e.features?.[0]?.id;
      if (featureId !== undefined) {
        hoveredRegionId = featureId as string;
        map.setFeatureState(
          { source: selectedLayer, id: hoveredRegionId },
          { hover: true }
        );
      }
    };

    const onMouseLeave = () => {
      if (hoveredRegionId !== null) {
        map.setFeatureState(
          { source: selectedLayer, id: hoveredRegionId },
          { hover: false }
        );
      }
      hoveredRegionId = null;
    };

    const onClick = (e: any) => {
      setPopupInfo(null);
      const feature = e.features?.[0];
      if (feature) {
        const { properties } = feature;

        // Calculamos el centroide del polígono o multipolígono
        const center = centroid(feature as Feature<Geometry>);
        const [longitude, latitude] = center.geometry.coordinates;

        // Establecemos la información del popup
        requestAnimationFrame(() => {
          setPopupInfo({
            ...properties,
            latitude,
            longitude,
          });
        });

        // Centramos el mapa en el centroide calculado
        mapRef.current?.flyTo({
          center: [longitude, latitude],
          zoom: 6, // Ajusta el nivel de zoom si es necesario
          animate: true,
          speed: 0.25,
        });
      }
    };

    if (mapData) {
      const style = createLayerStyle(
        mapData,
        selectedLayer,
        fillLayerId,
        borderLayerId
      );

      map.addSource(selectedLayer, { type: "geojson", data: mapData });
      style.layers.forEach((layer) =>
        map.addLayer(layer, "geographic-units-borders")
      );

      map.on("mousemove", fillLayerId, onMouseMove);
      map.on("mouseleave", fillLayerId, onMouseLeave);
      map.on("click", fillLayerId, onClick);
    }

    return () => {
      console.log("Cleaning up layers");
      if (map.getLayer(fillLayerId)) map.removeLayer(fillLayerId);
      if (map.getLayer(borderLayerId)) map.removeLayer(borderLayerId);
      if (map.getSource(selectedLayer)) map.removeSource(selectedLayer);
      map.off("mousemove", fillLayerId, onMouseMove);
      map.off("mouseleave", fillLayerId, onMouseLeave);
      map.off("click", fillLayerId, onClick);
    };
  }, [mapData, mapRef, selectedLayer]);

  const currentFilterBody = useRef({});

  const fetchAttractionData = useCallback(async () => {
    try {
      if (selectedLayer !== LayerOption.ATTRACTIVES) return;
      let filtersBody: { filters: { [key: string]: any } } = { filters: {} };
      const regionCodes = selectedUnits
        .filter((unit) => unit.type === "region")
        .map((unit) => unit.code);
      const regionCode = regionCodes.length === 1 ? regionCodes[0] : null;

      if (filters) {
        const newFilters = updateFilters(filters, "region_code", regionCode);
        filtersBody = buildFiltersBody(newFilters);
      } else {
        if (regionCode) {
          filtersBody = { filters: { region_code: regionCode } };
        } else {
          filtersBody = { filters: {} };
        }
      }

      if (
        JSON.stringify(currentFilterBody.current) ===
        JSON.stringify(filtersBody)
      ) {
        // setIsLoading(false);
        return;
      }

      if (filters) {
        const newFilters = updateFilters(filters, "region_code", regionCode);
        setFilters(newFilters);
        lastFilters.current = newFilters;
      }

      setAttractionData(null);
      currentFilterBody.current = filtersBody;
      setIsLoading(true);

      try {
        const response = await axios.post<AttractionData>(
          "/api/geojson/attractions",
          filtersBody
        );
        const { availableFilters, geojson } = response.data;
        setFilters(availableFilters);
        setTimeout(() => {
          setAttractionData(geojson);
          setIsLoading(false);
        }, 600);

        lastFilters.current = availableFilters;
      } catch (error) {
        console.error("Error fetching filtered attractions", error);
      }
    } catch (error) {
      console.error("Error fetching attractions", error);
    }
  }, [filters, selectedLayer, selectedUnits]);

  useEffect(() => {
    fetchAttractionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnits, filters]);

  const handleFilterChange = useCallback(
    (
      filterKey: string,
      selected: string | number | Array<string | number> | null
    ) => {
      if (filterKey === "region_code") {
        if (!selected) {
          setSelectedUnits([]);
        } else {
          setSelectedUnits([{ code: selected as number, type: "region" }]);
        }
        return;
      } else if (filters) {
        if (filterKey === "commune_code") {
          if (!selected) {
            setSelectedUnits((prev) =>
              prev.filter((unit) => unit.type !== "commune")
            );
          } else {
            setSelectedUnits((prev) => [
              ...prev.filter((unit) => unit.type !== "commune"),
              { code: selected as number, type: "commune" },
            ]);
          }
        }
        const newFilters = updateFilters(filters, filterKey, selected);
        setFilters(newFilters);
        // fetchAttractionData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  return (
    <>
      <GridComponent target_color="#ffffff66" autoAnimate={true} />

      {selectedLayer === LayerOption.ATTRACTIVES && attractionData && (
        <AttractionsManager mapData={attractionData} />
      )}

      <div className="fixed w-screen h-dvh inset-0 z-10 pointer-events-none text-white">
        {/* <div className="absolute w-full h-[45%] bg-gradient-to-b from-black/80 to-transparent"></div> */}
        {/* Header */}
        <div className="absolute h-1/4 w-full md:w-1/4 flex justify-start md:items-end p-1 ps-4 md:ps-8 lg:ps-12">
          <div className="relative h-fit">
            <span className="text-xl [text-shadow:14px_8px_2px_rgb(0_0_0_/_50%)]">
              Número
              <br /> de {LAYER_SHORT_LABELS[selectedLayer]}
            </span>

            <span className="absolute -bottom-2 left-0 transform translate-y-full min-w-12 min-h-12">
              {isLoading && (
                <div className="relative w-12 h-12">
                  <Loader color="white" />
                </div>
              )}
              {!isLoading && (
                <>
                  {selectedLayer === LayerOption.ATTRACTIVES &&
                    attractionData && (
                      <CountUp
                        start={0}
                        end={attractionData.features.length}
                        className="text-5xl font-bold"
                        decimal=","
                        separator="."
                      />
                    )}
                  {selectedLayer !== LayerOption.ATTRACTIVES && mapData && (
                    <CountUp
                      start={0}
                      end={
                        selectedLayer === LayerOption.ZOIT
                          ? 43
                          : mapData.features.length
                      }
                      className="text-5xl font-bold"
                      decimal=","
                      separator="."
                    />
                  )}
                </>
              )}
            </span>
          </div>
        </div>

        {/* Filtros */}
        <div className="absolute z-0 right-0 w-full top-1/4 md:w-1/2 flex items-start justify-end p-1 pe-4 md:pe-8 lg:pe-12">
          <div className="max-w-full flex flex-wrap gap-x-2 gap-y-3 justify-end transform -translate-y-[40px]">
            {/* <MapFilterSelect
              filter={{
                key: "layerOption",
                label: "Seleccionar Capa",
                multiple: false,
                required: true,
                type: "string",
                values: Object.entries(LayerOption).map(([key, value]) => ({
                  key: value,
                  label: LAYER_LABELS[value as LayerOption], // Usar las etiquetas en español
                })),
                selected: selectedLayer,
              }}
              onFilterChange={handleLayerChange}
            /> */}
            {filters &&
              (selectedLayer === LayerOption.ATTRACTIVES
                ? filters.map((filter) => (
                    <MapFilterSelect
                      key={filter.key}
                      filter={filter}
                      onFilterChange={handleFilterChange}
                    />
                  ))
                : filters.find((filter) => filter.key === "region_code") && (
                    <MapFilterSelect
                      filter={
                        filters.find((filter) => filter.key === "region_code")!
                      }
                      onFilterChange={handleFilterChange}
                    />
                  ))}
          </div>
        </div>
      </div>

      <LayerActionMenu
        layers={Object.entries(LayerOption).map(([key, value]) => ({
          key: value,
          label: LAYER_LABELS[value as LayerOption], // Usar las etiquetas en español
          icon: LAYER_ICONS[value as LayerOption],
        }))}
        selectedLayer={selectedLayer}
        onLayerChange={handleLayerChange}
      />

      <HelpWidget />

      {popupInfo && selectedLayer !== LayerOption.ATTRACTIVES && (
        <Popup
          latitude={popupInfo.latitude}
          longitude={popupInfo.longitude}
          onClose={() => setPopupInfo(null)}
        >
          <div className="relative p-3 w-[300px] h-auto min-h-40 max-w-[min(80vw,300px)] select-text">
            {selectedLayer === LayerOption.ZOIT ? (
              <>
                <h3 className="text-2xl font-semibold capitalize mb-3 leading-7">
                  {popupInfo.name?.toLowerCase()}
                </h3>
                <ul className="list-none p-0">
                  <li className="text-base leading-5">
                    <strong>Región:</strong> {popupInfo.region}
                  </li>
                  <li className="text-base leading-5">
                    <strong>Decreto:</strong> {popupInfo.decree}
                  </li>
                  <li className="text-base leading-5">
                    <strong>Fecha:</strong>{" "}
                    {new Date(popupInfo.date).toLocaleDateString("es-CL", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </li>
                  <li className="text-base leading-5">
                    <strong>Área:</strong> {popupInfo.shape_area} m²
                  </li>
                  <li className="text-base leading-5">
                    <strong>Perímetro:</strong> {popupInfo.shape_length} m
                    {/* {Number(popupInfo.shape_length).toLocaleString()} m */}
                  </li>
                </ul>
              </>
            ) : (
              <>
                <h3 className="text-2xl font-semibold capitalize mb-3 leading-7">
                  {popupInfo.name?.toLowerCase()}
                </h3>
                <ul className="list-none p-0">
                  <li className="text-base leading-5">
                    <strong>Clasificación:</strong> {popupInfo.CLASIFICAC}
                  </li>
                  <li className="text-base leading-5">
                    <strong>Comuna:</strong> {popupInfo.COMUNA}
                  </li>
                  <li className="text-base leading-5">
                    <strong>Provincia:</strong> {popupInfo.PROVINCIA}
                  </li>
                  <li className="text-base leading-5">
                    <strong>Región:</strong> {popupInfo.REGION}
                  </li>
                  <li className="text-base leading-5">
                    <strong>Superficie:</strong> {popupInfo.SUPERFICIE} km²
                  </li>
                  <li className="text-base leading-5">
                    <strong>Tipología:</strong>{" "}
                    {popupInfo.TIPOLOGÍA || popupInfo.Tipología}
                  </li>
                </ul>
              </>
            )}
          </div>
        </Popup>
      )}
    </>
  );
};

export default TouristView;
