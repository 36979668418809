// import {
//   RDxVisualizerLogoBlack,
//   RDxVisualizerLogoWhite,
// } from "src/assets/icons";

import { ReactSVG } from "react-svg";

import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { useLocation } from "react-router-dom";
// import { useAuth } from "@context/AuthContext";

const Attribution = () => {
  //   const { logout } = useAuth();
  const [appVersion, setAppVersion] = useState<string>("");
  const [isVisible, setIsVisible] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  useEffect(() => {
    // const getAppVersion = async () => {
    //   const appVersion = await window.electron.ipcRenderer.invoke(
    //     "getAppVersion"
    //   );
    const getAppVersion = "v1.0.0";
    setAppVersion(getAppVersion);
    // };
    // getAppVersion();
  }, []);

  useEffect(() => {
    if (isVisible) {
      gsap.to(modalRef.current, {
        duration: 0.5,
        opacity: 1,
        y: 0,
        ease: "ease.out",
      });
      gsap.to(backgroundRef.current, {
        duration: 0.5,
        opacity: 1,
        ease: "ease.out",
      });
    } else {
      gsap.to(modalRef.current, {
        duration: 0.5,
        opacity: 0,
        y: -20,
        ease: "ease.in",
      });
      gsap.to(backgroundRef.current, {
        duration: 0.5,
        opacity: 0,
        ease: "ease.in",
      });
    }
  }, [isVisible]);

  const handleLogoClick = () => {
    setIsVisible(true);
  };

  const handleBackgroundClick = () => {
    setIsVisible(false);
  };

  //   path="/analyst/predict"
  //   element={<PredictPage />}
  // />
  // <Route
  //   path="/analyst/timeline"
  //   element={<TimelineSelector />}
  // />
  // <Route
  //   path="/analyst/timeline/:varia

  const isDarkTheme =
    location.pathname.includes("/analyst/timeline/") ||
    location.pathname.includes("/analyst/analytics") ||
    location.pathname.includes("/analyst/predict") ||
    location.pathname.includes("/admin") ||
    location.pathname.includes("/login");

  const bottomSpacing =
    location.pathname.includes("/analyst") ||
    location.pathname.includes("/tourist")
      ? "bottom-[4.5rem] sm:bottom-4"
      : "bottom-4";

  const textColor = isDarkTheme ? "text-black" : "text-white";

  return (
    <>
      <div
        className={`remove-for-screenshot fixed ${bottomSpacing} w-full z-50 pointer-events-none ${textColor} transition-all duration-500 ease-out`}
      >
        <div className="w-full flex items-end justify-end sm:justify-between px-4 space-x-2">
          <div className="">
            <ReactSVG
              src="/assets/logos/logo-rdx-label.svg"
              className="portrait:hidden  h-auto w-32 cursor-pointer"
              onClick={handleLogoClick}
            />
            <ReactSVG
              src="/assets/logos/logo-rdx-icon.svg"
              className="landscape:hidden h-auto w-16 cursor-pointer"
              onClick={handleLogoClick}
            />
            <hr className="border-current mt-1"></hr>
          </div>
          <ReactSVG
            src="/assets/logos/logo-client.svg"
            className="h-auto w-10 sm:w-16 cursor-pointer"
            onClick={handleLogoClick}
          />
        </div>
      </div>
      <div
        className={`hidden show-for-screenshot fixed bottom-4 w-full z-50 pointer-events-none ${textColor} transition-all duration-500 ease-out`}
      >
        <div className="w-full flex items-end justify-end sm:justify-between px-4 space-x-3">
          <div className="">
            <ReactSVG
              src="/assets/logos/logo-rdx-label.svg"
              className="portrait:hidden  h-auto w-36 cursor-pointer"
              onClick={handleLogoClick}
            />
            <ReactSVG
              src="/assets/logos/logo-rdx-icon.svg"
              className="landscape:hidden h-auto w-20 cursor-pointer"
              onClick={handleLogoClick}
            />
            <hr className="border-current mt-1"></hr>
          </div>
          <ReactSVG
            src="/assets/logos/logo-client.svg"
            className="h-auto w-14 sm:w-20 cursor-pointer"
            onClick={handleLogoClick}
          />
        </div>
      </div>
      <div
        className={`hidden attribution fixed  ${bottomSpacing} right-16 sm:right-[unset]  sm:left-4 sm:bottom-3 z-50 space-y-1 ${textColor} transition-all duration-500 ease-out`}
      >
        <ReactSVG
          src="/assets/logos/logo-rdx-label.svg"
          className="portrait:hidden  h-auto w-32 cursor-pointer"
          onClick={handleLogoClick}
        />
        <ReactSVG
          src="/assets/logos/logo-rdx-icon.svg"
          className="landscape:hidden h-auto w-16 cursor-pointer"
          onClick={handleLogoClick}
        />
        <hr className="border-current"></hr>
      </div>

      <div
        className={`hidden fixed ${bottomSpacing} sm:bottom-3 right-4 z-50 space-y-1 pointer-events-none ${textColor} transition-all duration-500 ease-out`}
      >
        <ReactSVG
          src="/assets/logos/logo-client.svg"
          className="h-auto w-10 sm:w-16 cursor-pointer"
          onClick={handleLogoClick}
        />
      </div>

      <div
        className={`z-40 fixed w-full h-full top-0 left-0 font-helvetica ${
          isVisible ? "pointer-events-auto" : "pointer-events-none"
        }`}
      >
        <div className="relative w-full h-full p-10 flex items-center justify-center">
          <div
            ref={backgroundRef}
            className="absolute top-0 left-0 w-full h-full bg-black/40 cursor-pointer opacity-0"
            onClick={handleBackgroundClick}
          ></div>
          <div
            ref={modalRef}
            className="z-10 bg-[#E5E0DD] rounded-xl shadow-notification text-black max-w-lg opacity-0 transform -translate-y-20"
          >
            <div className="p-6">
              {/* <img
                src={RDxVisualizerLogoBlack}
                alt="RDx Visualizer"
                className="h-10 w-auto"
              /> */}
              <ReactSVG
                src="/assets/logos/logo-rdx-label.svg"
                className="h-auto w-48"
              />
            </div>
            <hr className="border-t border-black/20" />
            <div className="p-6 space-y-4">
              <div className="font-medium text-2xl">
                <p>RDx® Visualizer</p>
                <p>Subsecretaría de Turismo</p>
                <span className="text-xs">Version: {appVersion}</span>
              </div>
              <div className="py-12"></div>
              <div className="text-xs">
                <p className="font-regular">
                  @2024 RDx By Riolab. All rights reserved.
                </p>
                <p className="font-light">
                  Warning: This computer program is protected by copyright law
                  and international treaties. Unauthorized reproduction or
                  distribution of this program, or any portion of it, may result
                  in severe civil and criminal penalties, and will be prosecuted
                  to the maximum extent possible under the law.
                </p>
              </div>
              {/* <div className="flex justify-center">
                <button
                  className="underline underline-offset-2 text-sm"
                  onClick={logout}
                >
                  Cerrar sesión
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Attribution;
