import React, { useCallback, useEffect, useRef, useState } from "react";
import { VisualizationCategory } from "src/types/visualization";
import { useGeographicSelection } from "src/context/GeographicSelectionContext";
import { AvailableFilter } from "src/types/graph";
import RegionFilterSelect from "src/components/charts/RegionFilterSelect";
import CountUp from "react-countup";
import Loader from "src/components/Loader";
import { buildFiltersBody, updateFilters } from "src/utils/filterUtils";
import axios from "axios";
import useScrollMask from "src/hooks/useScrollMask";

// Definición de interfaces para los tipos de datos
interface Detail {
  type: "numeric_label" | "text" | "list" | "rich_text";
  value?: number | string;
  label?: string;
  items?: Array<Detail>;
  content?: string;
  percentage?: boolean;
}

interface Variable {
  title: string;
  details: Detail[];
}

// Función para renderizar cada tipo de detalle
const renderDetail = (detail: Detail) => {
  switch (detail.type) {
    case "numeric_label":
      return (
        <div className="flex items-center space-x-2 text-sm">
          {typeof detail.value === "number" ? (
            <CountUp
              end={detail.value}
              className="text-3xl font-bold text-blue-600"
              decimal=","
              separator="."
              suffix={detail.percentage ? "%" : ""}
            />
          ) : (
            <span className="text-4xl font-bold text-blue-600">
              {detail.value}
            </span>
          )}
          <span className="text-xs">{detail.label}</span>
        </div>
      );
    case "text":
      return <p className="text-sm">{detail.label || detail.value}</p>;
    case "list":
      if (!detail.items) return null;
      // Encontrar el número más largo en la lista
      const maxLength = Math.max(
        ...detail.items.map((item: Detail) => {
          if (item.type === "text") {
            return 0;
          }
          if (typeof item.value === "number") {
            // Formatear según si es porcentaje o no
            const formattedNumber = item.percentage
              ? new Intl.NumberFormat("es-CL", {
                  style: "percent",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }).format(item.value / 100) // Convertir a formato porcentual
              : new Intl.NumberFormat("es-CL", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0, // Sin decimales para números normales
                }).format(item.value); // Formatear como número regular

            return formattedNumber.length;
          }

          // Para valores no numéricos, usar la longitud del string
          return item.value?.toString().length || 0;
        })
      );

      return (
        <div>
          <h4 className={`font-medium text-xs text-sm mb-2 leading-5`}>
            {detail.label}
          </h4>
          <ul className="ml-0 space-y-1.5">
            {detail.items.map((item: any, index: number) => (
              <li key={index} className="w-full">
                {item.type === "text" && (
                  <p className="ps-4 text-xs">{item.value || item.label}</p>
                )}

                {/* Verificar si el item es un string, o tiene un 'value' numérico */}
                {item.type === "numeric_label" &&
                  (typeof item === "string" ? (
                    <div className="flex">
                      <span className="text-xs ">{item}</span>
                    </div>
                  ) : (
                    <div className="flex items-start space-x-2">
                      {typeof item.value === "number" ? (
                        <CountUp
                          end={item.value.toFixed(2)}
                          style={{
                            minWidth: `${maxLength}ch`,
                            textAlign: "right",
                          }}
                          className="text-xl text-blue-600"
                          // decimal="2"
                          // separator=""
                          decimalPlaces={1}
                          decimal=","
                          separator="."
                          suffix={item.percentage ? "%" : ""}
                        />
                      ) : (
                        <span
                          className="text-xl text-blue-600"
                          style={{
                            minWidth: `${maxLength}ch`,
                            textAlign: "right",
                          }}
                        >
                          {item.value}
                        </span>
                      )}
                      <span className="text-xs pt-1">{item.label}</span>
                    </div>
                  ))}
              </li>
            ))}
          </ul>
        </div>
      );
    case "rich_text":
      const renderRichText = (text: string) => {
        const parts = text.split(
          /({countup}|{\/countup}|{highlight}|{\/highlight})/
        );
        let isCountUp = false;
        let isHighlight = false;

        return parts.map((part, index) => {
          if (part === "{countup}") {
            isCountUp = true;
            return null;
          }
          if (part === "{/countup}") {
            isCountUp = false;
            return null;
          }
          if (part === "{highlight}") {
            isHighlight = true;
            return null;
          }
          if (part === "{/highlight}") {
            isHighlight = false;
            return null;
          }

          if (isCountUp) {
            const match = part.match(/^([\d.,-]+)(.*)$/);
            if (match) {
              const number = parseFloat(match[1].replace(",", "."));
              const suffix = match[2] || "";
              return (
                <CountUp
                  key={index}
                  end={number}
                  className="text-sm font-bold text-blue-600"
                  decimal=","
                  start={number * 0.9}
                  separator="."
                  decimals={number % 1 === 0 ? 0 : 2}
                  suffix={suffix}
                />
              );
            }
          }

          if (isHighlight) {
            return (
              <span key={index} className="text-blue-600 font-bold">
                {part}
              </span>
            );
          }

          return <span key={index}>{part}</span>;
        });
      };
      return (
        <p className="text-sm">{renderRichText(detail.value as string)}</p>
      );
    default:
      return <p className="text-sm">{detail.content}</p>;
  }
};

const ReaderModeViewer = ({
  categoryConfig,
}: {
  categoryConfig: VisualizationCategory | null;
}) => {
  const [reportData, setReportData] = useState<{
    variables: Variable[];
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState<AvailableFilter[] | null>(null);
  const isFetching = useRef<boolean>(false);

  const { selectedUnits } = useGeographicSelection();
  const bodyContentRef = useRef<HTMLDivElement>(null);
  useScrollMask(bodyContentRef);

  useEffect(() => {
    if (!categoryConfig) return;
    const fetchData = async () => {
      isFetching.current = true;
      setIsLoading(true);
      try {
        const selectedRegions = selectedUnits
          .filter((unit) => unit.type === "region")
          .map((unit) => unit.code);

        const response = await axios.post(categoryConfig.reportEndpoint, {
          filters: { region_codes: selectedRegions },
        });

        setReportData(response.data);
        setFilters(response.data.availableFilters);
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : "Error al obtener datos");
        setReportData(null);
      } finally {
        setIsLoading(false);
        isFetching.current = false;
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryConfig]);

  const handleFilterChange = useCallback(
    async (
      filterKey: string,
      selected: string | number | Array<string | number> | null
    ) => {
      if (!filters) return;

      const newFilters = updateFilters(filters, filterKey, selected);
      setFilters(newFilters);

      try {
        if (!categoryConfig) return;
        const response = await axios.post(
          categoryConfig.reportEndpoint,
          buildFiltersBody(newFilters)
        );
        setReportData(response.data);
        setFilters(response.data.availableFilters);
      } catch (error) {
        console.error("Error fetching filtered attractions", error);
      }
    },
    [filters, categoryConfig]
  );

  return (
    <>
      <div className="hidden landscape:flex sm:py-11 flex-1 flex flex-col max-w-60 ps-4 space-y-2">
        {filters &&
          filters
            .filter((filter) => filter.key === "region_codes")
            .map((filter) => (
              <RegionFilterSelect
                filter={filter}
                onFilterChange={handleFilterChange} // Este callback se encargará de actualizar la API automáticamente
                visualizationTheme={{
                  backgroundColor: "white",
                  textColor: "black",
                }}
                readerMode={true}
              />
            ))}
      </div>
      <div
        ref={bodyContentRef}
        className="relative sm:pt-0 pb-32 flex-1 p-4 overflow-auto select-text"
      >
        {error && (
          <div className="text-red-500 text-center">
            <p>{error}</p>
          </div>
        )}
        {isLoading ? (
          <Loader color="black" />
        ) : (
          <div className="p-5 max-w-screen-xl w-full mx-auto h-full select-text">
            <div className="pb-24 masonry sm:masonry-sm md:masonry-md lg:masonry-lg sm:columns-2 xl:columns-4 gap-4">
              {reportData?.variables.map(
                (variable: Variable, index: number) => (
                  <div
                    key={index}
                    className="break-inside-avoid mb-4 p-3 border rounded-lg shadow-sm"
                  >
                    <h3 className="text-lg font-semibold leading-6 mb-4">
                      {variable.title}
                    </h3>
                    <ul className="list-none space-y-2 font-sans">
                      {variable.details.map(
                        (detail: Detail, detailIndex: number) => (
                          <li key={detailIndex}>{renderDetail(detail)}</li>
                        )
                      )}
                    </ul>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReaderModeViewer;
