import React, { useRef, useEffect } from "react";
import gsap from "gsap";

interface LayerActionMenuProps {
  layers: {
    key: string; // Valor de la capa (ejemplo: "/api/geojson/attractions")
    label: string; // Etiqueta en español (ejemplo: "Atractivos turísticos")
    icon: React.ElementType; // Icono a usar
  }[];
  selectedLayer: string | null; // Capa seleccionada actualmente
  onLayerChange: (
    key: string,
    selectedOption: string | number | Array<string | number> | null
  ) => void; // Función para cambiar la capa activa
}
// (key: string,selectedOption: string | number | Array<string | number> | null)

const LayerActionMenu: React.FC<LayerActionMenuProps> = ({
  layers,
  selectedLayer,
  onLayerChange,
}) => {
  const barRef = useRef<HTMLDivElement>(null);
  const elementRefs = useRef<(HTMLButtonElement | HTMLAnchorElement)[]>([]);

  //   const { hasGuide, currentSlides, openWelcomeGuide } =
  //     useWelcomeGuideContext();

  useEffect(() => {
    if (!barRef.current || !elementRefs.current.length) {
      return;
    }
    gsap.killTweensOf([barRef.current, ...elementRefs.current]);

    gsap.set(barRef.current, { opacity: 0, scaleY: 0, scaleX: 0.9 });

    elementRefs.current.forEach((element) => {
      gsap.set(element, {
        opacity: 0,
        scale: 0,
        y: 60,
        filter: "blur(12px)",
      });
    });

    gsap.delayedCall(0.25, () => {
      gsap.to(barRef.current, {
        opacity: 1,
        scaleY: 1,
        scaleX: 1,
        duration: 0.5,
        ease: "ease.out",
      });

      elementRefs.current.forEach((element, index) => {
        gsap.to(element, {
          opacity: 1,
          scale: 1,
          y: 0,
          filter: "blur(0px)",
          delay: 0.1 * index,
          duration: 0.5,
          ease: "ease.out",
        });
      });
    });
  }, []);

  // Componente de Botón con referencia
  const ActionButton = ({
    icon: Icon,
    tooltip,
    onClick,
    index,
    active,
  }: {
    icon: React.ElementType;
    tooltip: string;
    onClick: () => void;
    index: number;
    active?: boolean;
  }) => (
    <button
      ref={(el) => (elementRefs.current[index] = el!)}
      className={`p-2.5 rounded-full border ${
        active ? "bg-black text-white" : "bg-white text-black"
      }`}
      onClick={onClick}
      data-tooltip-id="tooltip"
      data-tooltip-content={tooltip}
    >
      <Icon size={20} />
    </button>
  );

  return (
    <div className="hidden-for-screenshot pointer-events-none fixed bottom-0 sm:bottom-9 left-0 z-20 w-full h-auto flex justify-center">
      <div
        ref={barRef}
        className="w-full sm:w-fit opacity-0 pointer-events-auto flex justify-around sm:justify-center space-x-5 px-8 py-2.5 bg-white/10 backdrop-blur-md border-t border-x sm:border border-[#5D5D5D] rounded-t-3xl sm:rounded-full shadow-lg"
      >
        {layers.map((layer, index) => {
          const isActive = selectedLayer === layer.key;
          return (
            <ActionButton
              key={layer.key}
              icon={layer.icon}
              tooltip={layer.label}
              onClick={() => onLayerChange("layerOption", layer.key)}
              index={index}
              active={isActive}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LayerActionMenu;
