import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Importar useNavigate
import { getCategoryAndVisualization } from "src/config/visualizationService";
import { useReadingMode } from "src/context/ReadingModeContext";
import GraphModeViewer from "./GraphModeViewer";
import ReaderModeViewer from "./ReaderModeViewer";

import { useWelcomeGuide } from "src/hooks/useWelcomeGuide";
import { Slide } from "src/types/modal.d";

const AnalyticsViewer: React.FC = () => {
  // Obtener la key
  const { categoryId, graphId } = useParams<{
    categoryId?: string;
    graphId?: string;
  }>();

  // Obtener el estado de modo lectura
  const { isReadingMode } = useReadingMode();

  // Obtener la categoría y configuración de visualización
  const categoryAndVisualization =
    categoryId && graphId
      ? getCategoryAndVisualization(categoryId, graphId)
      : null;

  const navigate = useNavigate(); // Hook para navegar
  const categoryConfig = categoryAndVisualization?.categoryConfig;
  const visualizationConfig = categoryAndVisualization?.visualizationConfig;
  const isValid = categoryConfig && visualizationConfig;

  // const { openTutorial } = useTutorial();

  const slides = [
    {
      title: "Filtros dinámicos según el detalle seleccionado",
      subtitle:
        "Los filtros ubicados en la esquina superior derecha cambian dependiendo del detalle que estás visualizando. Por ejemplo, al analizar 'Pasajeros transportados en vuelos', puedes ajustar filtros como 'Vuelo', 'Origen', 'Destino', 'Años', 'Mes' o 'Anual'. Selecciona el filtro que mejor se ajuste a tu análisis.",
      image: "/assets/welcome-guide/analyst-view/filtros-detalle.png", // Captura que muestre los filtros
    },
    {
      title: "Alternar entre variación interanual",
      subtitle:
        "Si la gráfica permite visualizar la variación interanual, puedes activar el toggle ubicado en la parte superior. Esto te permitirá cambiar la visualización y analizar los cambios entre períodos.",
      image:
        "/assets/welcome-guide/analyst-view/toggle-variacion-interanual.png", // Captura del toggle
    },
    {
      title: "Descargar gráficos",
      subtitle:
        "Para descargar el gráfico de la variable que seleccionaste, simplemente debes hacer click en el símbolo de descarga ubicado en la parte de abajo del sitio.",
      // image: "https://via.placeholder.com/800x400",
      image: "/assets/sandbox/screenshot-button.png",
      // mobileImage: "https://via.placeholder.com/800x400",
    },
    {
      title: "Descargar tablas",
      subtitle:
        "Para descargar la tabla que seleccionaste, simplemente debes hacer click en Descargar CSV ubicado bajo la misma tabla al lado izquierdo.",
      image: "/assets/sandbox/download-button.png",
      // videoSrc: "https://www.w3schools.com/html/mov_bbb.mp4",
    },
  ] as Slide[];

  // const { openWelcomeGuide } =
  useWelcomeGuide(slides, AnalyticsViewer.name);

  useEffect(() => {
    if (!isValid) {
      console.error("Category not found");
      navigate("/analyst"); // Redirigir al home
    }
  }, [isValid, navigate]);

  // Condicional para definir el color de fondo y el color de texto
  const backgroundColor = isReadingMode
    ? "#FFFFFF" // Fondo blanco en modo lectura
    : categoryConfig?.theme.backgroundColor || "#d0e1f9"; // Fondo según el tema en modo gráfico

  const textColor = isReadingMode
    ? "#000"
    : categoryConfig?.theme.textColor || "#0f3057"; // Texto según el tema en modo gráfico

  if (!isValid) {
    return null;
  }

  return (
    <div
      className="absolute inset-0 z-10 w-screen h-screen pt-24 landscape:pt-32 pb-24 sm:pb-14  text-black flex landscape:flex-row flex-col overflow-hidden"
      style={{
        backgroundColor: backgroundColor, // Aplicar el color de fondo condicionalmente
        color: textColor, // Aplicar el color de texto condicionalmente
        transition: "background-color 700ms, color 700ms", // Transición de color
      }}
    >
      {isReadingMode ? (
        <ReaderModeViewer categoryConfig={categoryConfig} />
      ) : (
        <GraphModeViewer
          categoryConfig={categoryConfig}
          visualizationConfig={visualizationConfig}
        />
      )}
    </div>
  );
};

export default AnalyticsViewer;
