import React from "react";
import { Bar, Line } from "react-chartjs-2";
import { GraphDataResponse, GraphViewMode } from "src/types/graph";

interface ChartRendererProps {
  viewMode: GraphViewMode;
  data: GraphDataResponse;
}

const calculatePointRadius = (labelCount: number) => {
  const minRadius = 0;
  const maxRadius = 7;
  const minLabels = 8;
  const maxLabels = 24;

  if (labelCount <= minLabels) {
    return maxRadius;
  }

  if (labelCount >= maxLabels) {
    return minRadius;
  }

  const dynamicRadius =
    maxRadius -
    ((labelCount - minLabels) / (maxLabels - minLabels)) * maxRadius;
  return Math.max(minRadius, dynamicRadius);
};

const ChartRenderer: React.FC<ChartRendererProps> = ({ viewMode, data }) => {
  const chartData = {
    labels: data.data.labels,
    datasets: data.data.datasets.map((dataset: any) => ({
      ...dataset,
      label: dataset.label,
      data: dataset.data,
      backgroundColor: dataset.backgroundColor,
      borderColor: dataset.borderColor,
      fill: dataset.fill,
      tension: 0.4,
      pointRadius: calculatePointRadius(data.data.labels.length),
      pointHoverRadius: 20,
      barPercentage: 0.8,
      categoryPercentage:
        data.data.datasets.length > 1
          ? 1
          : data.data.labels.length <= 3
          ? 0.1
          : data.data.labels.length < 8
          ? 0.2
          : data.data.labels.length > 24
          ? 1
          : 0.3,
    })),
  };

  const handleResize = (chart: any) => {
    chart.resize();
  };

  const options = {
    responsive: true,
    onResize: handleResize,
    locale: "es-CL",
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: data.metadata.xAxis.stacked,
        border: {
          display: false,
        },
        type: "category" as const,
        title: {
          display: false,
          text: data.metadata.xAxis.label,
        },
        ticks: {
          maxTicksLimit: 24,
        },
      },
      y: {
        stacked: data.metadata.yAxis.stacked,
        border: {
          display: false,
        },
        grid: {
          color: (context: any) => {
            if (context.tick.value === 0) {
              return "#000";
            }
            return "rgba(0, 0, 0, 0.1)";
          },
        },
        title: {
          display: false,
          text: data.metadata.yAxis.label,
        },
      },
    },
    plugins: {
      legend: {
        // display: true,
        position: "bottom" as const,
        align: "center" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 6,
          boxHeight: 6,
          font: {
            size: 13,
            family: "IBM Plex Mono",
            weight: "normal" as const,
          },
          color: "#000",
        },
      },
      tooltip: {
        titleFont: { size: 20 },
        bodyFont: { size: 12 },
        titleAlign: "left" as const,
        enabled: true,
        displayColors: false,
        titleMarginBottom: 3,
        mode: "nearest" as const,
        intersect: false,
        caretPadding: viewMode === "line" ? 5 : 0,
        caretSize: 10,
        padding: 10,
        cornerRadius: 0,
        backgroundColor: "#fff",
        titleColor: "#000",
        bodyColor: "#000",
        borderWidth: 1,
        callbacks: {
          title: (context: any) => {
            let value = context[0].parsed.y;
            return formatNumber(value);
          },
          label: (context: any) => {
            if (data.metadata.xAxis.type === "date") {
              const yearAndMonth = context.label.split("-");
              if (yearAndMonth.length === 1) {
                return yearAndMonth[0];
              }
              const year = yearAndMonth[0];
              // const month = yearAndMonth[1];
              const month = parseInt(yearAndMonth[1]);

              const monthNames: { [key: string]: string } = {
                1: "Enero",
                2: "Febrero",
                3: "Marzo",
                4: "Abril",
                5: "Mayo",
                6: "Junio",
                7: "Julio",
                8: "Agosto",
                9: "Septiembre",
                10: "Octubre",
                11: "Noviembre",
                12: "Diciembre",
              };

              return `${monthNames[month]} del ${year}`;
            }
            return "";
          },
        },
      },
    },
  };
  const formatNumber = (value: number) => {
    const meta = data.metadata.yAxis;
    if (meta.type === "currency") {
      const currencyUnit = data.metadata.yAxis.unit || "CLP";
      if (currencyUnit === "UF") {
        const decimalValue = new Intl.NumberFormat("es-CL", {
          style: "decimal",
          //2 decimales
          // minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
        if (value === 0) {
          return "*";
        }
        return `UF ${decimalValue}`;
      } else if (currencyUnit === "USD") {
        const decimalValue = new Intl.NumberFormat("es-CL", {
          style: "decimal",
          //2 decimales
          // minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
        return `USD ${decimalValue}`;
      }
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: currencyUnit,
      }).format(value);
    } else if (meta.type === "percentage") {
      return new Intl.NumberFormat("es-CL", {
        style: "percent",
        maximumFractionDigits: 1,
      }).format(value);
    } else {
      return new Intl.NumberFormat("es-CL", {
        style: "decimal",
        //2 decimales
        // minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    }
  };

  const renderTable = () => {
    // Identificar columnas con valores uniformes iguales a 1
    const filteredDatasets = data.data.datasets.filter((dataset: any) => {
      return !dataset.data.every((value: number) => value === 1);
    });

    return (
      <table className="text-xs md:text-sm select-text min-w-full table-auto border-collapse border border-black mt-4">
        <thead>
          <tr>
            <th className="border border-black px-4 py-2 text-left">
              {data.metadata.xAxis.label}
            </th>
            {filteredDatasets.map((dataset: any, index: number) => (
              <th
                key={index}
                className="border border-black px-4 py-2 text-left"
              >
                {dataset.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.data.labels.map((label: string, rowIndex: number) => (
            <tr key={rowIndex}>
              <td className="border border-black px-4 py-2">{label}</td>
              {filteredDatasets.map((dataset: any, colIndex: number) => (
                <td
                  key={colIndex}
                  className="border border-black px-4 py-2 font-mono"
                >
                  {formatNumber(dataset.data[rowIndex])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  // Función para convertir la tabla a CSV
  const downloadCSV = () => {
    const rows = data.data.labels.map((label: string, rowIndex: number) => {
      const rowData = [label];
      data.data.datasets.forEach((dataset: any) => {
        rowData.push(dataset.data[rowIndex]);
      });
      return rowData.join(",");
    });

    const header = [
      data.metadata.xAxis.label,
      ...data.data.datasets.map((dataset: any) => dataset.label),
    ].join(",");

    const csvContent = [header, ...rows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="relative chart-container aspect-[5/4] landscape:aspect-[16/7.5]">
      {viewMode === "bar" && (
        <Bar data={chartData} options={options} height={130} />
      )}
      {viewMode === "line" && (
        <Line data={chartData} options={options} height={130} />
      )}
      {viewMode === "table" && (
        <div className="max-w-full overflow-x-auto">
          {renderTable()}
          <button
            className="mt-4 px-4 py-2 bg-black text-white rounded-full"
            onClick={downloadCSV}
          >
            Descargar CSV
          </button>
        </div>
      )}
    </div>
  );
};

export default ChartRenderer;
