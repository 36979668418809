// src/config/visualizationConfig.ts
import {
  VisualizationCategory,
  VisualizationTheme,
} from "src/types/visualization";

// amarillo 9E6902
// Estadísticas Territoriales
export const territorialTheme: VisualizationTheme = {
  backgroundColor: "color(srgb 1 0.9227 0.5077)",
  auxiliaryColor: "#9E6902",
  textColor: "#000000",
};
// Estadísticas de Flujos Turísticos
export const tourismFlowTheme: VisualizationTheme = {
  backgroundColor: "color(srgb 0.9975 0.8699 0.9541)",
  auxiliaryColor: "#C56EB2",
  textColor: "#000000",
};

// Estadísticas de la Oferta
export const supplyTheme: VisualizationTheme = {
  backgroundColor: "color(srgb 0.7626 0.9667 0.9979)",
  auxiliaryColor: "#51919B",
  textColor: "#000000",
};

// Estadísticas Económicas
export const economicTheme: VisualizationTheme = {
  backgroundColor: "color(srgb 0.7731 0.8535 0.6955)",
  auxiliaryColor: "#6E8855",
  textColor: "#000000",
};

// Definición de categorías con visualizaciones
export const visualizationCategories: VisualizationCategory[] = [
  {
    key: "territorial_statistics",
    name: "Estadísticas Territoriales",
    theme: territorialTheme,
    visualizations: [
      {
        key: "attractions",
        title: "Atractivos Turísticos",
        endpoint: "/api/graph/territorial_statistics/attractions",
        availableCharts: ["bar", "table"],
        defaultChart: "bar",
        source: {
          name: "Competitividad y Desarrollo, Territorio y Medio Ambiente, SERNATUR.",
        },
      },
      {
        key: "national_destinations",
        title: "Destinos turísticos",
        endpoint: "/api/graph/territorial_statistics/national_destinations",
        availableCharts: ["bar", "table"],
        defaultChart: "bar",
        source: {
          name: "Competitividad y Desarrollo, Territorio y Medio Ambiente, SERNATUR.",
        },
      },
      {
        key: "territorial_by_communes_zoit",
        title: "N° ZOIT",
        endpoint:
          "/api/graph/territorial_statistics/territorial_by_communes/zoit",
        availableCharts: ["bar", "table"],
        defaultChart: "bar",
        source: {
          name: "Unidad de Gestión Territorial y Cambio Climático, Subsecretaría de Turismo",
        },
      },
      {
        key: "territorial_by_communes_distinction",
        title: "Distinción turística",
        endpoint:
          "/api/graph/territorial_statistics/territorial_by_communes/distinction",
        availableCharts: ["bar", "table"],
        defaultChart: "bar",
        source: {
          name: "Competitividad y Desarrollo, Territorio y Medio Ambiente, SERNATUR.",
        },
      },
      {
        key: "territorial_by_communes_pladetur",
        title: "PLADETUR",
        endpoint:
          "/api/graph/territorial_statistics/territorial_by_communes/pladetur",
        availableCharts: ["bar", "table"],
        defaultChart: "bar",
        source: {
          name: "Competitividad y Desarrollo, Territorio y Medio Ambiente, SERNATUR.",
        },
      },
    ],
    reportEndpoint: "/api/report/territorial_statistics",
    previewEndpoint: "/api/report/territorial_statistics/preview",
  },
  {
    key: "tourism_flow_statistics",
    name: "Estadísticas de Flujos Turísticos",
    theme: tourismFlowTheme,
    visualizations: [
      {
        key: "foreign_tourist_arrivals",
        title: "Llegadas de Turistas Extranjeros",
        endpoint: "/api/graph/tourism_flow_statistics/foreign_tourist_arrivals",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        source: {
          name: "Unidad de Estudios de la Subsecretaría de Turismo y Departamento de Estadísticas del SERNATUR, en base a información proporcionada por la Jefatura Nacional de Migraciones y Policía Internacional de Chile.",
        },
      },
      {
        key: "total_overnight_tourism",
        title: "Viajes con pernoctación total",
        endpoint: "/api/graph/tourism_flow_statistics/total_overnight_tourism",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        source: {
          name: "Unidad de Estudios de la Subsecretaría de Turismo y Departamento de Estadísticas del SERNATUR, en base a información proporcionada por el Big Data para el Turismo Interno.",
        },
      },
      {
        key: "occasional_overnight_tourism",
        title: "Viajes con pernoctación ocasional",
        endpoint:
          "/api/graph/tourism_flow_statistics/occasional_overnight_tourism",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        source: {
          name: "Unidad de Estudios de la Subsecretaría de Turismo y Departamento de Estadísticas del SERNATUR, en base a información proporcionada por el Big Data para el Turismo Interno.",
        },
      },
      {
        key: "departures_of_chilean_residents",
        title: "Salidas de Residentes por motivos Turísticos",
        endpoint:
          "/api/graph/tourism_flow_statistics/departures_of_chilean_residents",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
      },
      {
        key: "passengers_transported_on_flights",
        title: "Pasajeros transportados en vuelos",
        endpoint:
          "/api/graph/tourism_flow_statistics/passengers_transported_on_flights",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        source: {
          name: "Junta de Aeronáutica Civil (JAC).",
        },
      },
      {
        key: "historical_cruise",
        title: "Cruceros",
        endpoint: "/api/graph/tourism_flow_statistics/historical_cruise",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        source: {
          name: "Puertos del Conos Sur.",
        },
      },
      {
        key: "snaspe",
        title: "SNASPE",
        endpoint: "/api/graph/tourism_flow_statistics/snaspe",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        // source: {
        //   name: "Puertos del Conos Sur.",
        // },
      },
      {
        key: "emat",
        title: "EMAT",
        endpoint: "/api/graph/tourism_flow_statistics/emat",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        // source: {
        //   name: "Puertos del Conos Sur.",
        // },
      },
    ],
    reportEndpoint: "/api/report/tourism_flow_statistics",
    previewEndpoint: "/api/report/tourism_flow_statistics/preview",
  },
  {
    key: "supply_statistics",
    name: "Estadísticas de la Oferta",
    theme: supplyTheme,
    visualizations: [
      {
        key: "act_employment",
        title: "N° Ocupados ACT",
        endpoint: "/api/graph/supply_statistics/act_employment",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        description:
          "* La línea de tiempo y las estadísticas del número de ocupados en las Actividades Económicas (ACT) indican que cada mes representa el mes central del trimestre móvil medido. Por ejemplo, enero corresponde al trimestre móvil de diciembre, enero y febrero; febrero al trimestre de enero, febrero y marzo; marzo abarca febrero, marzo y abril, y así sucesivamente.",
        source: {
          name: "Unidad de Estudios de la Subsecretaría de Turismo y Departamento de Estadísticas del SERNATUR, en base a la estimación de Ocupados ACT a partir de la Encuesta Nacional de Empleo del Instituto Nacional de Estadísticas (INE).",
        },
      },
      {
        key: "act_company_by_commune_number_of_companies",
        title: "N° de Empresas",
        endpoint:
          "/api/graph/supply_statistics/act_company_by_commune/number_of_companies",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        source: {
          name: "Departamento de Estadísticas de SERNATUR y Unidad de Estudios de la Subsecretaría de Turismo en base a las Estadísticas de Empresas del Servicio de Impuestos Internos (SII).",
        },
      },
      {
        key: "act_company_by_commune_annual_sales",
        title: "Ventas Anuales en UF",
        endpoint:
          "/api/graph/supply_statistics/act_company_by_commune/annual_sales",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        description:
          "Aquellos campos donde aparece un '*' corresponde a valores que debido a restricciones relativas a la reserva tributaria (según el Artículo 35 del Código Tributario) no son factibles de informar, pues corresponde a: (i) Un valor declarado por un número igual o inferior a 10 informantes, o (ii) casos que mediante un cálculo aritmético simple se despeje el valor de un registro con 10 o menos declarantes.",

        source: {
          name: "Departamento de Estadísticas de SERNATUR y Unidad de Estudios de la Subsecretaría de Turismo en base a las Estadísticas de Empresas del Servicio de Impuestos Internos (SII).",
        },
      },
      {
        key: "act_company_by_commune_dependent_workers",
        title: "N° Trabajadores Dependientes",
        endpoint:
          "/api/graph/supply_statistics/act_company_by_commune/dependent_workers",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",

        source: {
          name: "Departamento de Estadísticas de SERNATUR y Unidad de Estudios de la Subsecretaría de Turismo en base a las Estadísticas de Empresas del Servicio de Impuestos Internos (SII).",
        },
      },
      {
        key: "act_company_by_commune_net_income_dependent",
        title: "Renta Neta UF Trab. Dependientes",
        endpoint:
          "/api/graph/supply_statistics/act_company_by_commune/net_income_dependent",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        description:
          "Aquellos campos donde aparece un '*' corresponde a valores que debido a restricciones relativas a la reserva tributaria (según el Artículo 35 del Código Tributario) no son factibles de informar, pues corresponde a: (i) Un valor declarado por un número igual o inferior a 10 informantes, o (ii) casos que mediante un cálculo aritmético simple se despeje el valor de un registro con 10 o menos declarantes.",

        source: {
          name: "Departamento de Estadísticas de SERNATUR y Unidad de Estudios de la Subsecretaría de Turismo en base a las Estadísticas de Empresas del Servicio de Impuestos Internos (SII).",
        },
      },
      {
        key: "act_company_by_commune_contract_workers",
        title: "N° Trabajadores Honorarios",
        endpoint:
          "/api/graph/supply_statistics/act_company_by_commune/contract_workers",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        source: {
          name: "Departamento de Estadísticas de SERNATUR y Unidad de Estudios de la Subsecretaría de Turismo en base a las Estadísticas de Empresas del Servicio de Impuestos Internos (SII).",
        },
      },
      {
        key: "act_company_by_commune_net_income_contract",
        title: "Renta Neta UF Trab. Honorarios",
        endpoint:
          "/api/graph/supply_statistics/act_company_by_commune/net_income_contract",
        availableCharts: ["line", "bar", "table"],
        defaultChart: "line",
        description:
          "Aquellos campos donde aparece un '*' corresponde a valores que debido a restricciones relativas a la reserva tributaria (según el Artículo 35 del Código Tributario) no son factibles de informar, pues corresponde a: (i) Un valor declarado por un número igual o inferior a 10 informantes, o (ii) casos que mediante un cálculo aritmético simple se despeje el valor de un registro con 10 o menos declarantes.",

        source: {
          name: "Departamento de Estadísticas de SERNATUR y Unidad de Estudios de la Subsecretaría de Turismo en base a las Estadísticas de Empresas del Servicio de Impuestos Internos (SII).",
        },
      },
    ],
    reportEndpoint: "/api/report/supply_statistics",
    previewEndpoint: "/api/report/supply_statistics/preview",
  },
  {
    key: "economic_statistics",
    name: "Estadísticas Económicas",
    theme: economicTheme,
    visualizations: [
      {
        key: "pib_regional",
        title: "PIB Turístico",
        endpoint: "/api/graph/economic_statistics/pib_regional",
        availableCharts: ["bar", "line", "table"],
        defaultChart: "line",
        source: {
          name: "Unidad de Estudios de la Subsecretaría de Turismo y Departamento de Estadísticas del SERNATUR.",
        },
      },
      {
        key: "ipc",
        title: "IPC",
        endpoint: "/api/graph/economic_statistics/ipc",
        availableCharts: ["line", "table"],
        defaultChart: "line",
      },
      {
        key: "tourist_foreign_currency_income",
        title: "Ingreso de Divisas de Turistas Extranjeros",
        endpoint:
          "/api/graph/economic_statistics/tourist_foreign_currency_income",
        availableCharts: ["bar", "line", "table"],
        defaultChart: "line",
      },
      {
        key: "tourist_foreign_currency_outflow",
        title: "Egreso de divisas de residentes salidos al extranjero",
        endpoint:
          "/api/graph/economic_statistics/tourist_foreign_currency_outflow",
        availableCharts: ["bar", "line", "table"],
        defaultChart: "line",
      },
    ],
    reportEndpoint: "/api/report/economic_statistics",
    previewEndpoint: "/api/report/economic_statistics/preview",
  },
];
