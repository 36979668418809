// Definimos la estructura de los nodos y los endpoints
// export interface DataNodeConfig extends d3.SimulationNodeDatum {
//   id: string;
//   label: string;
//   value: number;
//   endpoint?: string;
//   children?: DataNodeConfig[];
// }

import { VisualizationTheme } from "src/types/visualization";
import { supplyTheme, tourismFlowTheme } from "src/config/visualizationConfig";
import { Data } from "racing-bars";
import { VisualizationSource } from "src/types/visualization";
import { AvailableFilter } from "src/types/graph";
export interface DataNodeConfig extends d3.SimulationNodeDatum {
  id: string;
  label: string;
  endpoint?: string;
  children?: DataNodeConfig[];
  theme?: VisualizationTheme; // Tema opcional solo para los nodos padres
  radius?: number; // Agregar campo opcional para el radio
  description?: string; // Agregar campo opcional para la descripción
  source?: VisualizationSource;
}

export interface MetaData {
  title: string;
  unit: string;
  description: string;
}

export interface TimeData {
  start_year: number;
  start_month: number;
  end_year: number;
  end_month: number;
  interval_type: string;
}

export interface TimelineData {
  data: Data[];
  meta: MetaData;
  time: TimeData;
  availableFilters?: AvailableFilter[];
}

export const timelineConfig: DataNodeConfig[] = [
  {
    id: "1",
    label: "EMAT",
    theme: tourismFlowTheme,
    children: [
      {
        id: "1.1",
        label: "Ranking de Tasa de Ocupación hotelera según región",
        endpoint: "/api/timeline/tourism_flow_statistics/emat",
      },
    ],
  },
  {
    id: "2",
    label: "Llegadas de turistas extranjeros",
    theme: tourismFlowTheme,
    source: {
      name: "Unidad de Estudios de la Subsecretaría de Turismo y Departamento de Estadísticas del SERNATUR, en base a información proporcionada por la Jefatura Nacional de Migraciones y Policía Internacional de Chile.",
    },
    children: [
      {
        id: "2.1",
        label: "Ranking por país",
        endpoint:
          "/api/timeline/tourism_flow_statistics/foreign_tourist_arrivals/country_ranking",
      },
      {
        id: "2.2",
        label: "Ranking por continente",
        endpoint:
          "/api/timeline/tourism_flow_statistics/foreign_tourist_arrivals/continent_ranking",
      },
    ],
  },
  {
    id: "3",
    label: "Viajes con pernoctación",
    theme: tourismFlowTheme,
    source: {
      name: "Unidad de Estudios de la Subsecretaría de Turismo y Departamento de Estadísticas del SERNATUR, en base a información proporcionada por el Big Data para el Turismo Interno.",
    },
    children: [
      {
        id: "3.1",
        label: "Ranking por región de origen",
        endpoint:
          "/api/timeline/tourism_flow_statistics/overnight_tourism/origin_region_ranking",
      },
      {
        id: "3.2",
        label: "Ranking por región de destino",
        endpoint:
          "/api/timeline/tourism_flow_statistics/overnight_tourism/destination_region_ranking",
      },
      {
        id: "3.3",
        label: "Ranking por comuna de origen",
        endpoint:
          "/api/timeline/tourism_flow_statistics/overnight_tourism/origin_commune_ranking",
      },
      {
        id: "3.4",
        label: "Ranking por comuna de destino",
        endpoint:
          "/api/timeline/tourism_flow_statistics/overnight_tourism/destination_commune_ranking",
      },
    ],
  },
  {
    id: "4",
    label: "N° Ocupados en las ACT",
    theme: supplyTheme,
    source: {
      name: "Unidad de Estudios de la Subsecretaría de Turismo y Departamento de Estadísticas del SERNATUR, en base a la estimación de Ocupados ACT a partir de la Encuesta Nacional de Empleo del Instituto Nacional de Estadísticas (INE).",
    },
    children: [
      {
        id: "4.2",
        label: "Ranking por región",
        endpoint:
          "/api/timeline/supply_statistics/act_employment/region-ranking",
        description:
          "* Cada mes representa el mes central del trimestre móvil medido. Por ejemplo, enero corresponde al trimestre móvil de diciembre, enero y febrero; febrero al trimestre de enero, febrero y marzo.",
      },
      {
        id: "4.3",
        label: "Ranking por tipo de ACT",
        endpoint:
          "/api/timeline/supply_statistics/act_employment/sector-ranking",
        description:
          "* Cada mes representa el mes central del trimestre móvil medido. Por ejemplo, enero corresponde al trimestre móvil de diciembre, enero y febrero; febrero al trimestre de enero, febrero y marzo.",
      },
    ],
  },
  {
    id: "5",
    label: "Empresas ACT según territorio",
    theme: supplyTheme,
    source: {
      name: "Departamento de Estadísticas de SERNATUR y Unidad de Estudios de la Subsecretaría de Turismo en base a las Estadísticas de Empresas del Servicio de Impuestos Internos (SII).",
    },
    children: [
      {
        id: "5.1",
        label: "Número de Empresas",
        endpoint:
          "/api/timeline/supply_statistics/act_company_by_commune/number_of_companies",
      },
      {
        id: "5.2",
        label: "Ventas en UF",
        endpoint:
          "/api/timeline/supply_statistics/act_company_by_commune/annual_sales",
      },
      {
        id: "5.3",
        label: "Trabajadores Dependientes",
        endpoint:
          "/api/timeline/supply_statistics/act_company_by_commune/dependent_workers",
      },
      {
        id: "5.4",
        label: "Trabajadores a Honorarios",
        endpoint:
          "/api/timeline/supply_statistics/act_company_by_commune/contract_workers",
      },
      {
        id: "5.5",
        label: "Renta Neta de Trabajadores Dependientes",
        endpoint:
          "/api/timeline/supply_statistics/act_company_by_commune/net_income_dependent",
      },
      {
        id: "5.6",
        label: "Renta Neta de Trabajadores a Honorarios",
        endpoint:
          "/api/timeline/supply_statistics/act_company_by_commune/net_income_contract",
      },
    ],
  },
  {
    id: "6",
    label: "Empresas ACT según tamaño",
    theme: supplyTheme,
    source: {
      name: "Departamento de Estadísticas de SERNATUR y Unidad de Estudios de la Subsecretaría de Turismo en base a las Estadísticas de Empresas del Servicio de Impuestos Internos (SII).",
    },
    children: [
      {
        id: "6.1",
        label: "Número por tipo de ACT",
        endpoint:
          "/api/timeline/supply_statistics/act_company_by_size/number_of_companies_by_act_type",
      },
      {
        id: "6.2",
        label: "Ventas en UF",
        endpoint:
          "/api/timeline/supply_statistics/act_company_by_size/annual_sales_by_act_type",
      },
      {
        id: "6.3",
        label: "Trabajadores dependientes",
        endpoint:
          "/api/timeline/supply_statistics/act_company_by_size/dependent_workers_by_act_type",
      },
      {
        id: "6.4",
        label: "Trabajadores a honorarios",
        endpoint:
          "/api/timeline/supply_statistics/act_company_by_size/contract_workers_by_act_type",
      },
      {
        id: "6.5",
        label: "Renta de dependientes",
        endpoint:
          "/api/timeline/supply_statistics/act_company_by_size/net_income_dependent_by_act_type",
      },
      {
        id: "6.6",
        label: "Renta de honorarios",
        endpoint:
          "/api/timeline/supply_statistics/act_company_by_size/net_income_contract_by_act_type",
      },
    ],
  },
];
