import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactMapGL, { ViewState } from "react-map-gl";
import { Map, StyleSpecification } from "mapbox-gl";
import { useMap } from "src/context/MapContext";
import { useGeographicSelection } from "src/context/GeographicSelectionContext";
import {
  zoomToMultipleRegionsByCode,
  zoomOutToAllRegions,
  zoomToFeature,
} from "src/utils/mapUtils";
import { MAPBOX_ACCESS_TOKEN, MAPBOX_STYLE } from "src/config";
import { GeoJSONData } from "src/types/geojson";
import axios from "axios";
import {
  defaultRegionStyle,
  createAnalistRegionStyle,
  createTouristRegionStyle,
} from "./mapStyleBuilder";
import { useKeydownListener } from "src/hooks/useKeydownListener";
import { useResizeListener } from "src/hooks/useResizeListener";
import Loader from "src/components/Loader";
import { UserProfile } from "src/App";
import { Link } from "react-router-dom";
import { regionsConfig } from "src/config/regionsConfig";
import { Img } from "react-image";
import { GoHome } from "react-icons/go";

enum MapState {
  Uninitialized,
  Loading,
  Loaded,
  Error,
}

const InteractiveMap: React.FC<{
  userProfile: UserProfile;
  children?: React.ReactNode;
}> = ({ userProfile, children }) => {
  const { mapRef, viewport, setViewport, isRegionInteractionEnabled } =
    useMap();
  const { selectedUnits, setSelectedUnits } = useGeographicSelection();
  const regionsData = useRef<GeoJSONData | null>(null);
  const [mapState, setMapState] = useState<MapState>(MapState.Uninitialized);
  const fetchOnce = useRef(false);
  const isRegionInteractionEnabledRef = useRef(true);

  const touristStyleRef = useRef<StyleSpecification>(defaultRegionStyle);
  const analistStyleRef = useRef<StyleSpecification>(defaultRegionStyle);

  const isTouristView = useRef(true);

  const maxZoom = userProfile === UserProfile.ANALYST ? 7 : 16;
  const [lastSelectedUnit, setLastSelectedUnit] = useState<number>(1);
  const isImagesCompactedRef = useRef(false);
  const [isImagesCompacted, setIsImagesCompacted] = useState(false);
  const lastZoom = useRef(0);

  const getBearingByScreenWidth = () =>
    window.matchMedia("(min-width: 768px)").matches ? 90 : 0;

  useKeydownListener(
    (key, pathname) =>
      key === "Escape" && (pathname === "/analyst" || pathname === "/tourist"),
    () => setSelectedUnits([])
  );

  useResizeListener(() => {
    setViewport((prev) => ({
      ...prev,
      bearing: getBearingByScreenWidth(),
    }));
  }, 200);

  useEffect(() => {
    if (userProfile === UserProfile.UNKNOWN) {
      setSelectedUnits([]);
      // mapRef.current?.getMap()._markers.forEach((marker) => marker.remove());
    } else {
      isTouristView.current = userProfile === UserProfile.TOURIST;
      if (isTouristView.current) {
        //just select the first region
        setSelectedUnits((prevSelectedUnits) => {
          return prevSelectedUnits.length > 0
            ? [prevSelectedUnits[0]]
            : prevSelectedUnits;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    isRegionInteractionEnabledRef.current = isRegionInteractionEnabled;
  }, [isRegionInteractionEnabled]);
  useEffect(() => {
    if (mapState !== MapState.Uninitialized || fetchOnce.current) return;

    fetchOnce.current = true;
    setMapState(MapState.Loading);

    const fetchGeojsonData = async () => {
      try {
        const response = await axios.get("/api/geojson/regions");
        // const response = await axios.get("/api/geojson/region/1");

        // const response = await axios.get("/api/geojson/destinations");

        const data: GeoJSONData = response.data;

        if (data?.features) {
          data.features = data.features.map((feature, index) => ({
            ...feature,
            id: feature.properties?.codregion ?? `region-${index}`,
          }));

          regionsData.current = data;
          // styleRef.current = createRegionStyle(data);
          touristStyleRef.current = createTouristRegionStyle(data);
          analistStyleRef.current = createAnalistRegionStyle(data);
          setMapState(MapState.Loaded);
        } else {
          throw new Error("Invalid GeoJSON data");
        }
      } catch (error) {
        console.error("Error fetching geojson data:", error);
        setMapState(MapState.Error);
      }
    };

    fetchGeojsonData();
  }, [mapState]);

  const selectedRegionCommunesData = useRef<GeoJSONData | null>(null);
  const clearCommunesFromRef = (
    styleRef: React.MutableRefObject<StyleSpecification>,
    sourceId: string,
    layerIds: string[],
    map?: Map
  ) => {
    // Filtra las capas para remover las asociadas al source de comunas
    styleRef.current.layers = styleRef.current.layers.filter(
      (layer) => !layerIds.includes(layer.id)
    );

    // Remueve el source asociado
    delete styleRef.current.sources[sourceId];

    // Remueve las capas del mapa
    if (map) {
      const style = map.getStyle();
      if (style) {
        layerIds.forEach((layerId) => {
          if (style.layers.find((layer) => layer.id === layerId)) {
            map.removeLayer(layerId);
          }
        });
        if (style.sources[sourceId]) {
          map.removeSource(sourceId);
        }
      }
    }
  };

  const fetchSelectedRegionCommunes = async (
    selectedRegionCode: number,
    callback?: (regionData: GeoJSONData) => void
  ) => {
    try {
      // Limpiar el estilo actual relacionado con comunas
      clearCommunesFromRef(
        touristStyleRef,
        "geographic-communes-units",
        [
          "geographic-communes-units-fills",
          "geographic-communes-units-borders",
        ],
        mapRef.current?.getMap()
      );

      // Solicitar los nuevos datos
      const response = await axios.get(
        `/api/geojson/region/${selectedRegionCode}/communes`
      );
      const regionData: GeoJSONData = response.data;

      if (regionData?.features) {
        regionData.features = regionData.features.map((feature, index) => ({
          ...feature,
          id:
            feature.properties?.cod_comuna ??
            `comuna-${selectedRegionCode}-${index}`,
        }));

        selectedRegionCommunesData.current = regionData;

        // Crear los nuevos estilos para las comunas
        const temp = createTouristRegionStyle(
          regionData,
          "geographic-communes-units",
          "geographic-communes-units-fills",
          "geographic-communes-units-borders"
        );

        // Actualizar el `ref` con los nuevos estilos y fuentes
        // get map layers
        const style = mapRef.current?.getMap().getStyle();

        // touristStyleRef.current = {
        //   ...touristStyleRef.current,
        //   layers: [...touristStyleRef.current.layers, ...temp.layers],
        //   sources: {
        //     ...touristStyleRef.current.sources,
        //     "geographic-communes-units":
        //       temp.sources["geographic-communes-units"],
        //   },
        // };
        if (style) {
          touristStyleRef.current = {
            ...style,
            layers: [...style.layers, ...temp.layers],
            sources: {
              ...style.sources,
              "geographic-communes-units":
                temp.sources["geographic-communes-units"],
            },
          };
        } else {
          touristStyleRef.current = {
            ...touristStyleRef.current,
            layers: [...touristStyleRef.current.layers, ...temp.layers],
            sources: {
              ...touristStyleRef.current.sources,
              "geographic-communes-units":
                temp.sources["geographic-communes-units"],
            },
          };
        }

        callback?.(regionData);

        setMapState(MapState.Loaded);
      } else {
        throw new Error("Invalid GeoJSON data");
      }
    } catch (error) {
      console.error(
        `Error fetching communes for region ${selectedRegionCode}:`,
        error
      );
      setMapState(MapState.Error);
    }
  };

  const updateSelectedUnits = useCallback(() => {
    const map = mapRef.current?.getMap();
    if (!map || !regionsData.current) return;

    // map.showPadding = true;
    const regionCodes = selectedUnits
      .filter((unit) => unit.type === "region")
      .map((unit) => unit.code);

    const communesCodes = selectedUnits
      .filter((unit) => unit.type === "commune")
      .map((unit) => unit.code);

    const bearing = getBearingByScreenWidth();

    if (regionCodes.length > 0) {
      setLastSelectedUnit(regionCodes[0]);
      if (isTouristView.current) {
        fetchSelectedRegionCommunes(regionCodes[0], (regionData) => {
          if (communesCodes.length === 1) {
            const communeFeature = regionData.features.find(
              (feature) => feature.properties?.cod_comuna === communesCodes[0]
            );
            if (communeFeature) zoomToFeature(map, communeFeature, bearing);
            // if (communeFeature) {
            //   const [longitude, latitude] = (communeFeature.geometry as any)
            //     .coordinates[0][0];
            //   map.setCenter([longitude, latitude]);
            //   map.setZoom(10);
            // }
          }
        });
      }
    }

    if (communesCodes.length === 1) {
    } else if (regionCodes.length > 0) {
      zoomToMultipleRegionsByCode(
        map,
        regionsData.current,
        regionCodes,
        bearing,
        10,
        isTouristView.current
      );
    } else {
      //get center of the current viewport
      const { width, height } = map.getContainer().getBoundingClientRect();
      const getCenterOfScreen = map.unproject([width / 2, height / 2]);
      map.setPadding({ top: 0, bottom: 0, left: 0, right: 0 });
      map.setCenter(getCenterOfScreen);
      zoomOutToAllRegions(map, regionsData.current, bearing);

      clearCommunesFromRef(
        touristStyleRef,
        "geographic-communes-units",
        [
          "geographic-communes-units-fills",
          "geographic-communes-units-borders",
        ],
        mapRef.current?.getMap()
      );
      touristStyleRef.current = {
        ...touristStyleRef.current,
        layers: [...touristStyleRef.current.layers],
        sources: {
          ...touristStyleRef.current.sources,
        },
      };
    }

    const allRegionCodes = regionsData.current.features.map(
      (feature) => feature.properties?.codregion
    );

    allRegionCodes.forEach((code) => {
      map.setFeatureState(
        { source: "geographic-units", id: code },
        { selected: selectedUnits.some((unit) => unit.code === code) }
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, selectedUnits]);

  useEffect(() => {
    updateSelectedUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnits]);

  const addMapBehaviors = (map: Map) => {
    let hoveredRegionId: string | null = null;
    const maxZoomToAllowSelection = 7;
    map.on("zoom", () => {
      if (!isTouristView.current) return;
      const mapZoom = map.getZoom();

      if (hoveredRegionId !== null) {
        const featureState = map.getFeatureState({
          source: "geographic-units",
          id: hoveredRegionId,
        });
        const isSelect = featureState?.selected;
        if (isSelect === true) {
          // if (clickedRegionId !== null && clickedRegionId === hoveredRegionId) {
          map.setFeatureState(
            { source: "geographic-units", id: hoveredRegionId },
            { hover: mapZoom < maxZoomToAllowSelection }
          );
        }
      }

      const isImagesCompacted = mapZoom > 8.5;
      if (isImagesCompacted !== isImagesCompactedRef.current) {
        setIsImagesCompacted(isImagesCompacted);
        isImagesCompactedRef.current = isImagesCompacted;
        requestAnimationFrame(() => {
          // const { width, height } = map.getContainer().getBoundingClientRect();
          // const getCenterOfScreen = map.unproject([width / 2, height / 2]);

          if (isImagesCompacted) {
            // map.setPadding({ top: 20, bottom: 20, left: 20, right: 20 });
            // console.log("Breakpoint", map.getZoom());
            // console.log(map.speedIndexTiming);
          }
          // else {
          //   map.setPadding({ top: 20, bottom: 20, left: 20, right: 600 });
          // }

          // console.log("Breakpoint", map.getZoom());
          // map.setCenter(getCenterOfScreen);
        });
      }

      //state machine

      const currentZoom = map.getZoom();

      // const communesCodes = selectedUnits
      //   .filter((unit) => unit.type === "commune")
      //   .map((unit) => unit.code);
      // if (communesCodes.length === 0) {
      const minZoomToAllowSelection = 4;
      const isZoomingOut = currentZoom < minZoomToAllowSelection;
      const wasZoomingOut = lastZoom.current < minZoomToAllowSelection;
      if (isZoomingOut && !wasZoomingOut) {
        const { width, height } = map.getContainer().getBoundingClientRect();
        const getCenterOfScreen = map.unproject([width / 2, height / 2]);
        map.setCenter(getCenterOfScreen);

        setSelectedUnits([]);
      }
      // }
      lastZoom.current = currentZoom;
    });

    map.on("mousemove", "geographic-units-fills", (e) => {
      if (hoveredRegionId !== null) {
        map.setFeatureState(
          { source: "geographic-units", id: hoveredRegionId },
          { hover: false }
        );
      }
      if (!isRegionInteractionEnabledRef.current) return;

      const featureId = e.features?.[0]?.id;
      if (featureId !== undefined) {
        hoveredRegionId = featureId as string;

        //check if is selected{selected: true, hover: false}
        const featureState = map.getFeatureState({
          source: "geographic-units",
          id: hoveredRegionId,
        });
        const isSelect = featureState?.selected;
        if (isSelect === true) {
          const mapZoom = map.getZoom();

          if (mapZoom > maxZoomToAllowSelection) {
            map.setFeatureState(
              { source: "geographic-units", id: hoveredRegionId },
              { hover: false }
            );
            return;
          }
        }

        map.setFeatureState(
          { source: "geographic-units", id: hoveredRegionId },
          { hover: true }
        );
      }
    });

    map.on("mouseleave", "geographic-units-fills", () => {
      if (hoveredRegionId !== null) {
        map.setFeatureState(
          { source: "geographic-units", id: hoveredRegionId },
          { hover: false }
        );
      }
      hoveredRegionId = null;
    });

    map.on("mousemove", "draws-fill", (e) => {
      if (hoveredRegionId !== null) {
        map.setFeatureState(
          { source: "geographic-units", id: hoveredRegionId },
          { hover: false }
        );
      }
      hoveredRegionId = null;
    });
    map.on("click", "geographic-units-fills", (e) => {
      if (!isRegionInteractionEnabledRef.current) return;
      const target = e.originalEvent.target as HTMLElement | null;

      if (!target?.classList?.contains("mapboxgl-canvas")) {
        // console.log("Click ignorado: No es un canvas.");
        return;
      }

      const codRegion = e.features?.[0]?.properties?.codregion;
      // hoveredRegionId

      console.log("Click en region", codRegion);
      // if (codRegion !== undefined && hoveredRegionId === codRegion) {
      if (codRegion !== undefined) {
        if (isTouristView.current) {
          //just can select one region
          setSelectedUnits((prevSelectedUnits) => {
            const alreadySelected = prevSelectedUnits.some(
              (unit) => unit.code === codRegion
            );

            if (alreadySelected) {
              const mapZoom = map.getZoom();
              if (mapZoom > maxZoomToAllowSelection) {
                return prevSelectedUnits;
              } else {
                return [];
              }
            } else {
              return [{ type: "region", code: codRegion }];
            }
          });
        } else {
          setSelectedUnits((prevSelectedUnits) => {
            const alreadySelected = prevSelectedUnits.some(
              (unit) => unit.code === codRegion
            );
            return alreadySelected
              ? prevSelectedUnits.filter((unit) => unit.code !== codRegion)
              : [...prevSelectedUnits, { type: "region", code: codRegion }];
          });
        }
      }
    });
  };

  const regionImageSection = useMemo(() => {
    if (userProfile !== UserProfile.TOURIST) return null;
    return (
      <>
        <div
          className={
            "pointer-events-none absolute z-0 top-0 right-0  bg-black transition-all duration-500 ease-in-out " +
            (selectedUnits.filter((unit) => unit.type === "region").length === 1
              ? isImagesCompacted
                ? "w-full h-1/4 md:h-1/2 md:w-1/4"
                : "h-1/4 w-full md:w-1/2 md:h-1/2"
              : "w-0 h-0")
          }
        >
          <div className="w-full h-full relative">
            <Img
              src={
                process.env.PUBLIC_URL +
                "/assets/region_images/region_" +
                lastSelectedUnit +
                "_banner.webp"
              }
              className="w-full h-full object-cover"
              alt={"Region " + lastSelectedUnit}
              loader={<Loader color="white" />}
            />
          </div>
        </div>
        <div
          className={
            "pointer-events-none hidden md:block absolute z-0 bottom-1/4 right-0 bg-black transition-all duration-500 ease-in-out delay-100 " +
            (selectedUnits.filter((unit) => unit.type === "region").length === 1
              ? isImagesCompacted
                ? "w-1/4 h-1/4"
                : "w-1/4 h-2/5"
              : "w-0 h-0")
          }
        >
          <div className="w-full h-full relative">
            <Img
              src={
                process.env.PUBLIC_URL +
                "/assets/region_images/region_" +
                lastSelectedUnit +
                "_card.webp"
              }
              className="w-full h-full object-cover"
              alt={"Region " + lastSelectedUnit}
              loader={<Loader color="white" />}
            />
          </div>
        </div>
      </>
    );
  }, [userProfile, isImagesCompacted, lastSelectedUnit, selectedUnits]);

  const useProfileSelector = useMemo(() => {
    if (userProfile === UserProfile.UNKNOWN) return null;

    const regions = selectedUnits
      .filter((unit) => unit.type === "region")
      .map((unit) => unit.code);

    const label =
      regions.length > 1
        ? "Comparando..."
        : regions.length === 1
        ? regionsConfig[regions[0]].shortName
        : "Chile";

    const currentProfile =
      userProfile === UserProfile.TOURIST ? "Turismo" : "Analista";

    return (
      <>
        <div
          className={
            "absolute bottom-0 sm:hidden w-full  bg-gradient-to-t from-black/60 to-transparent pointer-events-none " +
            (userProfile === UserProfile.TOURIST ? "h-40" : "h-40")
          }
        ></div>
        <div
          className={
            "xl:flex xl:items-end xl:flex-row-reverse fixed z-10 sm:bottom-14 left-4 x space-y-2 " +
            (userProfile === UserProfile.TOURIST ? "bottom-20" : "bottom-20")
          }
        >
          <Link
            to="/"
            className="w-fit space-x-2 text-sm bg-white p-1 px-3 rounded-full mb-0 flex justify-center items-center h-min"
          >
            <GoHome size={16} />
            <span>| {currentProfile}</span>
          </Link>
          <p
            className={
              regions.length === 0
                ? "max-w-[65vw] text-white text-5xl xl:text-6xl"
                : "text-white text-3xl xl:text-5xl"
            }
          >
            {label}/
          </p>
        </div>
      </>
    );
  }, [userProfile, selectedUnits]);

  return (
    <div className="bg-black relative w-full h-full">
      <div className="absolute top-0 left-0 w-full h-full pointer-events-none z-0">
        <ReactMapGL
          {...viewport}
          mapStyle={MAPBOX_STYLE}
          projection={{ name: "mercator" }}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          maxZoom={maxZoom}
          minZoom={3.5}
          maxPitch={0}
          interactive={false}
        />
      </div>

      {mapState === MapState.Loading && (
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <Loader color="white" />
        </div>
      )}

      {mapState === MapState.Error && (
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <div className="text-white text-lg">Error al cargar el mapa</div>
        </div>
      )}

      {mapState === MapState.Loaded && (
        <ReactMapGL
          ref={(ref) => (mapRef.current = ref)}
          {...viewport}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          // mapStyle={styleRef.current}
          mapStyle={
            userProfile === UserProfile.TOURIST
              ? touristStyleRef.current
              : userProfile === UserProfile.ANALYST
              ? analistStyleRef.current
              : defaultRegionStyle
          }
          projection={{ name: "mercator" }}
          maxZoom={maxZoom}
          minZoom={3.5}
          maxPitch={0}
          onMove={(event: { viewState: ViewState }) =>
            setViewport(event.viewState)
          }
          onLoad={() => {
            const map = mapRef.current?.getMap();
            if (regionsData.current && map) {
              updateSelectedUnits();
              addMapBehaviors(map);
            }
          }}
        >
          {regionImageSection}
          <div className="absolute w-full h-[35%] bg-gradient-to-b from-black/80 to-transparent pointer-events-none"></div>
          {children}
        </ReactMapGL>
      )}

      {useProfileSelector}
    </div>
  );
};

export default InteractiveMap;
