// admin/shared/AdminModal.tsx
import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Transition } from "@headlessui/react";

interface AdminModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
}

const AdminModal: React.FC<AdminModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
}) => {
  // Reset scroll position when modal opens
  useEffect(() => {
    if (isOpen) {
      window.scrollTo(0, 0);
    }
  }, [isOpen]);

  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="font-sans fixed inset-0 left-0 top-0 w-screen h-screen z-40 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white rounded-xl w-full max-w-screen-lg max-h-[80vh] min-h-[80vh] mx-4 relative border border-white overflow-hidden">
          {/* Close Button */}
          <button
            className="absolute top-4 right-4 w-8 h-8 rounded-full bg-black text-white shadow-md hover:shadow-lg hover:bg-gray-700 flex items-center justify-center transition-all"
            onClick={onClose}
          >
            <IoMdClose size={20} />
          </button>

          {/* Modal Header */}
          {title && (
            <div className="p-4 border-b border-gray-200">
              <h2 className="text-xl font-semibold">{title}</h2>
            </div>
          )}

          {/* Modal Content */}
          <div
            className="p-6 overflow-y-auto"
            style={{
              maxHeight: "calc(80vh - 4rem)",
              height: "calc(80vh - 4rem)",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default AdminModal;
